import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'DASHBOARD',
    icon: 'HomeIcon',
    href: 'https://app.robinhoodswap.finance'
  },
  {
    label: 'TRADE',
    icon: 'TradeIcon',
    initialOpenState: true,
    items: [
      {
        label: 'Exchange',
        href: '/swap'
      },
      {
        label: 'Liquidity',
        href: '/pool'
      }
    ]
  },
  {
    label: 'FARMS',
    icon: 'FarmIcon',
    href: 'https://app.robinhoodswap.finance/farms'
  },
  {
    label: 'POOLS',
    icon: 'PoolIcon',
    href: 'https://app.robinhoodswap.finance/pools'
  },
  // {
  //   label: 'Lottery',
  //   icon: 'TicketIcon',
  //   href: 'https://www.goosedefi.com/lottery'
  // },

  {
    label: 'CHARTS',
    icon: 'InfoIcon',
    initialOpenState: false,
    items: [
      {
        label: 'Dex Guru',
        href: 'https://dex.guru/token/0xD5779F2F9D7D239228e4e78bC78F50768661A081-bsc',
      },
      {
        label: 'Poo Coin',
        href: 'https://poocoin.app/tokens/0xD5779F2F9D7D239228e4e78bC78F50768661A081',
      },
      {
        label: 'Bogged Finance',
        href: 'https://charts.bogged.finance/?token=0xD5779F2F9D7D239228e4e78bC78F50768661A081',
      },
      {
        label: 'Dex Tools',
        href: 'https://www.dextools.io/app/pancakeswap/pair-explorer/0x5b9f811D38fc6C1017E0774C552235b33F564DEb',
      },
    ],
  },

  {
    label: 'MORE',
    icon: 'MoreIcon',
    items: [
      // {
      //   label: 'Github',
      //   href: '',
      // },
      {
        label: 'GitBook',
        href: 'https://robinhoodswap.gitbook.io/robinhoodswap/',
      },
      {
        label: 'Feedback',
        href: 'https://docs.google.com/forms/d/e/1FAIpQLSfW9jaHzkhT-S-wc_VYHzD9K-EVy4-ud8_8O882fVdSmPx-Nw/viewform',
      },
    ],
  },
  {
    label: 'HOW TO',
    icon: 'MoreIcon',
    items: [
      {
        label: 'GET RBH',
        href: 'https://robinhoodswap.gitbook.io/robinhoodswap/how-to-guides/getting-rbh',
      },
      {
        label: 'Add Liquidity',
        href: 'https://robinhoodswap.gitbook.io/robinhoodswap/how-to-guides/getting-lps',
      },
      {
        label: 'How to Farm ',
        href: 'https://robinhoodswap.gitbook.io/robinhoodswap/how-to-guides/how-to-farm',
      },
    ],
  },
  {
    label: 'AUDIT',
    icon: 'AUDITIcon',
    href: 'https://robinhoodswap.gitbook.io/robinhoodswap/pillars/audit'
  },

  // {
  //   label: 'Info',
  //   icon: 'InfoIcon',
  //   items: [
  //     {
  //       label: 'PancakeSwap',
  //       href: 'https://pancakeswap.info/token/0xF952Fc3ca7325Cc27D15885d37117676d25BfdA6',
  //     },
  //     {
  //       label: 'CoinGecko',
  //       href: 'https://www.coingecko.com/en/coins/goose-finance',
  //     },
  //     {
  //       label: 'CoinMarketCap',
  //       href: 'https://coinmarketcap.com/currencies/goose-finance/',
  //     },
  //     {
  //       label: 'AstroTools',
  //       href: 'https://app.astrotools.io/pancake-pair-explorer/0x19e7cbecdd23a16dfa5573df54d98f7caae03019',
  //     },
  //   ]
  // },
  // {
  //   label: 'More',
  //   icon: 'MoreIcon',
  //   items: [
  //     {
  //       label: "Github",
  //       href: "https://github.com/goosedefi/",
  //     },
  //     {
  //       label: "Docs",
  //       href: "https://goosedefi.gitbook.io/goose-finance/",
  //     },
  //     {
  //       label: "Blog",
  //       href: "https://goosefinance.medium.com/",
  //     },
  //   ],
  // },
  // {
  //   label: 'Partnerships/IFO',
  //   icon: 'GooseIcon',
  //   href: 'https://docs.google.com/forms/d/e/1FAIpQLSe7ycrw8Dq4C5Vjc9WNlRtTxEhFDB1Ny6jlAByZ2Y6qBo7SKg/viewform?usp=sf_link',
  // },
  // {
  //   label: 'Audit by Hacken',
  //   icon: 'AuditIcon',
  //   href: 'https://www.goosedefi.com/files/hackenAudit.pdf',
  // },
  // {
  //   label: 'Audit by CertiK',
  //   icon: 'AuditIcon',
  //   href: 'https://certik.org/projects/goose-finance',
  // },
]

export default config
